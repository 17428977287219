<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card no-body>
          <b-card-header>
            <div class="user-details justify-content-between align-items-center flex-wrap">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <h5 class="mb-0">
                    {{ reserData.nama_customer }}
                  </h5>
                  <small class="mail-date-time text-muted">{{ reserData.no_hp }}</small>
                </b-col>
              </b-row>
            </div>

            <div>
              <h3 class="text-right">
                {{ reserData.area }}
              </h3>
              <b-badge
                pill
                :variant="statusReservasi(reserData.value).variant"
              >
                {{ statusReservasi(reserData.status).display }}
              </b-badge>
            </div>
          </b-card-header>
          <hr>
          <b-card-body class="mail-message-wrapper">
            <div class="d-flex mail-message">
              <p class="card-text">
                Gender Mitra :
              </p>
              <p class="ml-1 m-0">
                {{ genderMitra(reserData.gender_mitra) }}
              </p>
            </div>
            <div class="d-flex mail-message">
              <p class="card-text">
                Gender Customer :
              </p>
              <p class="ml-1">
                {{ genderCustomer(reserData.gender_customer) }}
              </p>
            </div>
            <!-- Layanan -->
            <div class="mail-message">
              <h5>{{ reserData.induk_layanan }}</h5>
              <span>{{ reserData.layanan }}</span>
              <p>
                {{ reserData.alamat_pemesan }}
              </p>
            </div>
            <div class="mt-0">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-card
                    class="shadow-lg p-1 mt-50 mb-2 mb-lg-0 pr-2"
                    no-body
                    border-variant="light"
                  >
                    <small>Catatan Lokasi</small>
                    {{ reserData.alamat_customer }}
                  </b-card>
                </b-col>
              </b-row>
              <!-- alamat Detail customer -->
            </div>
          </b-card-body>

          <b-card-footer>
            <div class="mail-attachments">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div>
                    <span>Status Pembayaran :</span>
                    <span class="ml-1">{{ statusPembarayan(reserData.status_pembayaran) }}</span>
                  </div>
                  <div class="d-flex mt-1">
                    <h5 class="mr-1">
                      Harga Layanan :
                    </h5>
                    <h5>
                      {{ reserData.harga }}
                    </h5>
                  </div>
                  <div class="d-flex">
                    <h4 class="mr-1">
                      Total :
                    </h4>
                    <h4>
                      {{ reserData.total }}
                    </h4>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div class="text-right">
                    <span>Transport : {{ reserData.biaya_transport }}</span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-card>
          <div>
            <cari-mitra
              :customer="reserData.lokasi_customer"
              :vertikal="reserData.vertikal_layanan"
              :alamat="reserData.alamat_pemesan"
              @arahkanOrderan="arahkanOrderan"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
  BRow, BCol, BCardFooter, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { db } from '@/firebase'
import CariMitra from './CariMitra.vue'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BBadge,

    CariMitra,
  },
  props: ['id'],
  data() {
    return {
      reserData: '',
      btnloading: false,
    }
  },
  created() {
    this.getReservasi()
  },
  methods: {
    getReservasi() {
      db.collection('reservasi').doc(this.id).get().then(snapshot => {
        this.reserData = snapshot.data()
      })
    },
    genderCustomer(customer) {
      const variantGender = {
        0: 'Pria',
        1: 'Wanita',
        2: 'Bebas',
      }
      return variantGender[customer]
    },
    genderMitra(mitra) {
      const variantGender = {
        0: 'Pria',
        1: 'Wanita',
        2: 'Bebas',
      }
      return variantGender[mitra]
    },
    statusReservasi(status) {
      if (status === 0) return { variant: 'light-primary', display: 'Membuat pesanan reservasi' }
      if (status === 1) return { variant: 'light-primary', display: 'Mendapatkan mitra' }
      if (status === 2) return { variant: 'light-primary', display: 'Customer melakukan pembayaran' }
      return { variant: 'light-secondary', display: 'Not found' }
    },
    statusPembarayan(statuspembayaran) {
      const variantPembayaran = {
        true: 'Terbayarkan',
        false: 'Belum terbayarkan',
      }
      return variantPembayaran[statuspembayaran]
    },
    async arahkanOrderan(datamitra) {
      const harga = await this.$swal({
        title: 'Input biaya transport',
        html: '<p>Format : 20000 </p',
        input: 'text',
        confirmButtonText: 'Konfirmasi',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      this.$swal({
        title: 'Arahkan pesanan ke mitra ini ?',
        text: 'Konfirmasi jika anda ingin mengarahkan pesanan',
        showCancelButton: true,
        confirmButton: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (harga.value === null) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Biaya transport wajib di isi',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } else if (result.value) {
          /* eslint-disable radix */
          this.reserData.id = this.id
          db.collection('reservasi').doc(this.id).set({
            uid_mitra: datamitra.uid,
            nama_mitra: datamitra.nama,
            foto_mitra: datamitra.fotoProfil,
            no_hp_mitra: datamitra.no_hp,
            biaya_transport: parseInt(harga.value),
            total: this.reserData.harga + parseInt(harga.value),
            status: 1,
          }, { merge: true }).then(async () => {
            const options = {
              url: 'https://us-central1-mecare-life.cloudfunctions.net/arahkanOrderMitra',
              method: 'POST',
              data: {
                mitra: datamitra,
                orderan: this.reserData,
              },
            }
            this.getReservasi()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil mengarahkan mitra ke orderan ini',
                icon: 'CheckIcon',
                variant: 'success',
                position: 'bottom',
              },
            })
            await this.$axios(options).then(value => {
              if (value.data.status === 'ok') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Berhasil mengirimkan notifikasi',
                    icon: 'BellIcon',
                    variant: 'success',
                    position: 'bottom',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Gagal mengirimkan notifikasi',
                    icon: 'BellIcon',
                    variant: 'danger',
                    position: 'bottom',
                  },
                })
              }
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Gagal mengirimkan notifikasi',
                  icon: 'BellIcon',
                  variant: 'danger',
                  position: 'bottom',
                },
              })
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">

</style>
