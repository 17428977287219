<template>
  <div>
    <b-button
      variant="primary"
      block
      @click="getMitraAround"
    >
      <span v-if="!btnloading">
        Cari Mitra
        <feather-icon
          icon="SearchIcon"
          size="16"
        />
      </span>
      <b-spinner
        v-else
        small
      />
    </b-button>
    <vue-bottom-sheet
      ref="myBottomSheet"
      class="p-1"
      :rounded="false"
      :click-to-close="false"
    >
      <div class="bottom-sheet">
        <div class="d-flex justify-content-between mb-1">
          <div class="w-75">
            <h4>Cari Mitra</h4>
            <small>{{ alamat }}</small>
          </div>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="closeBottomSheet"
          />
        </div>
        <b-tabs>
          <b-tab title="Mitra di sekitar">
            <div
              v-if="mitraAround.length"
            >
              <b-card
                v-for="item in mitraAround"
                :key="item.no_hp"
                border-variant="primary"
                class="m-0 mt-1 p-0"
              >
                <div class="d-flex justify-content-between p-0">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      size="52"
                      :src="item.fotoProfil"
                      :variant="`light-primary`"
                      class="mr-1"
                      style="cursor: pointer"
                      @click="detailMitra(item.no_hp)"
                    />
                    <div class="d-inline-block">
                      <h6
                        class="text-primary font-weight-bolder m-0 mb-25"
                        style="cursor: pointer"
                        @click="detailMitra(item.no_hp)"
                      >
                        {{ item.nama }}
                      </h6>
                      <small
                        class="text-secondary m-0"
                      >
                        {{ `${item.no_hp} • ` }}
                      </small>
                      <b-badge
                        :variant="statusMitra(item.status_aktif).variant"
                      >
                        {{ statusMitra(item.status_aktif).display }}
                      </b-badge>
                      <b-badge
                        class="mr-2"
                        :variant="item.status_order ? 'light-primary' : 'light-secondary'"
                      >
                        {{ item.status_order ? 'Orderan On' : 'Orderan Off' }}
                      </b-badge>
                      <br>
                      <small
                        class="text-secondary m-0 mr-25"
                      >
                        <feather-icon
                          icon="MapPinIcon"
                          size="12"
                          class="text-body"
                        />
                        {{ countDistance(item.hitMetadata.distance) }}
                      </small>
                      <small
                        class="text-secondary m-0 mr-25"
                      >
                        <feather-icon
                          icon="UserIcon"
                          size="12"
                          class="text-body"
                        />
                        {{ item.jenis_kelamin == 0 ? 'Laki Laki' : 'Perempuan' }}
                      </small>
                    </div>
                  </div>
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="arahkanOrderan(item)"
                    >
                      <feather-icon
                        icon="ExternalLinkIcon"
                        size="16"
                        class="mr-50 text-primary"
                      />
                      <span class="align-middle text-primary">Arahkan Orderan</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon
                        icon="BellIcon"
                        size="16"
                        class="mr-50 text-info"
                      />
                      <span class="align-middle text-info">Beri Notifikasi</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="copyNohp(item)"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        size="16"
                        class="mr-50 text-warning"
                      />
                      <span class="align-middle text-warning">Copy No HP</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="item.status_aktif != 8"
                      @click="mitraNonaktif(item)"
                    >
                      <feather-icon
                        icon="AlertCircleIcon"
                        size="16"
                        class="mr-50 text-danger"
                      />
                      <span class="align-middle text-danger">Nonaktif</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="item.status_aktif != 4"
                      @click="mitraAktif(item)"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="16"
                        class="mr-50 text-primary"
                      />
                      <span class="align-middle text-primary">Aktifkan Mitra</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-card>
            </div>
            <h4
              v-else
              class="text-secondary text-center font-weight-lighter mt-3"
            >
              Tidak ada mitra {{ vertikalMitra(vertikal) }} di sekitar sini 😞
            </h4>
          </b-tab>
          <b-tab title="Semua mitra">
            <ais-instant-search
              :search-client="searchClient"
              index-name="mitra"
            >
              <ais-search-box
                placeholder="Cari mitra (bisa gunakan nama atau nomor hp)"
                :class-names="{
                  'ais-SearchBox-input': 'form-control',
                  'ais-SearchBox-submit': 'd-none',
                  'ais-SearchBox-reset': 'd-none',
                }"
                class="mt-1"
              >
                <div
                  slot="submit-icon"
                >
                  Cari
                </div>
              </ais-search-box>
              <ais-hits
                :class-names="{
                  'ais-Hits-list' : 'hitsList',
                }"
              >
                <div
                  slot="item"
                  slot-scope="{ item }"
                  class="mt-1"
                >
                  <b-card
                    border-variant="primary"
                    class="m-0"
                  >
                    <div class="card-body d-flex justify-content-between p-0">
                      <div class="d-inline-block">
                        <h6
                          class="text-primary font-weight-bolder"
                          style="cursor: pointer"
                          @click="detailMitra(item.objectID)"
                        >
                          {{ item.nama }}
                        </h6>
                        <p
                          class="text-secondary m-0"
                        >
                          {{ item.no_hp }}
                        </p>
                      </div>
                      <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          @click="arahkanOrderan(item)"
                        >
                          <feather-icon
                            icon="ExternalLinkIcon"
                            size="16"
                            class="mr-50 text-primary"
                          />
                          <span class="align-middle text-primary">Arahkan Orderan</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="copyNohpAllMitra(item)"
                        >
                          <feather-icon
                            icon="CopyIcon"
                            size="16"
                            class="mr-50 text-warning"
                          />
                          <span class="align-middle text-warning">Copy No HP</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-card>
                </div>
              </ais-hits>
            </ais-instant-search>
          </b-tab>
        </b-tabs>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import {
  BButton, BSpinner, BCard, BBadge,
  BDropdown, BDropdownItem, BAvatar,
  BTabs, BTab,
} from 'bootstrap-vue'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import algoliasearch from 'algoliasearch/lite'
import { AisInstantSearch, AisSearchBox } from 'vue-instantsearch'
import { db } from '@/firebase'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BSpinner,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BTabs,
    BTab,
    AisInstantSearch,
    AisSearchBox,

    VueBottomSheet,
  },
  props: ['customer', 'vertikal', 'alamat'],
  data() {
    return {
      searchClient: algoliasearch(
        '4LASHL8CBV',
        '0f873fc12e5ca3f430fbe1014a65c384',
      ),
      btnloading: false,
      mitraAround: [],
    }
  },
  methods: {
    async getMitraAround() {
      this.btnloading = true
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/getMitraAround',
        method: 'POST',
        data: {
          location: {
            lat: this.customer.latitude,
            long: this.customer.longitude,
          },
          vertikal: this.vertikal,
        },
      }
      await this.$axios(options).then(res => {
        this.btnloading = false
        this.mitraAround = [...res.data]
      }).catch(() => {
        this.btnloading = false
      })
      this.$refs.myBottomSheet.open()
    },
    statusMitra(status) {
      if (status === 1) return { variant: 'light-info', display: 'Sedang Diproses' }
      if (status === 2) return { variant: 'light-info', display: 'Sedang Diverifikasi' }
      if (status === 3) return { variant: 'light-danger', display: 'Ditolak' }
      if (status === 4) return { variant: 'light-primary', display: 'Aktif' }
      if (status === 5) return { variant: 'light-warning', display: 'Saldo Kurang' }
      if (status === 6) return { variant: 'light-warning', display: 'Harus Konseling' }
      if (status === 7) return { variant: 'light-warning', display: 'Perlu Retraining' }
      if (status === 8) return { variant: 'light-danger', display: 'Dinonaktifkan' }
      if (status === 9) return { variant: 'light-danger', display: 'Diblokir' }
      return { variant: 'light-secondary', display: 'tidak diketahui' }
    },
    vertikalMitra(vl) {
      if (vl) {
        const vertikalVariant = {
          vl01: 'Akupunktur',
          vl02: 'Auto',
          vl03: 'Barber',
          vl04: 'Bekam',
          vl05: 'Beauty',
          vl06: 'Cleaning',
          vl07: 'Massage',
          vl08: 'Cuci AC',
        }
        return vertikalVariant[vl]
      }

      return 'Layanan Belum Diisi'
    },
    countDistance(distance) {
      if (distance < 1) {
        return `${Math.ceil(distance * 1000)} M`
      }
      return `${Math.floor(distance * 100) / 100} KM`
    },
    closeBottomSheet() {
      this.$refs.myBottomSheet.close()
    },
    arahkanOrderan(mitra) {
      this.closeBottomSheet()
      this.$emit('arahkanOrderan', mitra)
    },
    detailMitra(noHp) {
      this.closeBottomSheet()
      this.$router.push({ name: 'DetailMitra', params: { id: noHp } })
    },
    mitraNonaktif(mitra) {
      this.closeBottomSheet()
      this.$swal({
        title: 'Nonaktifkan mitra?',
        text: 'Konfirmasi jika anda ingin menonaktifkan mitra',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          db.collection('mitra').doc(mitra.id).update({
            status_aktif: 8,
          }, { merge: true }).then(async () => {
            const index = this.mitraAround.indexOf(mitra)
            this.mitraAround[index].status_aktif = 8
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menonaktifkan mitra',
                icon: 'CheckIcon',
                variant: 'success',
                position: 'bottom',
              },
            })
            this.$refs.myBottomSheet.open()
          })
        }
      })
    },
    mitraAktif(mitra) {
      this.closeBottomSheet()
      this.$swal({
        title: 'Aktifkan mitra?',
        text: 'Konfirmasi jika anda ingin mengaktifkan mitra',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          db.collection('mitra').doc(mitra.id).update({
            status_aktif: 4,
          }, { merge: true }).then(async () => {
            const index = this.mitraAround.indexOf(mitra)
            this.mitraAround[index].status_aktif = 4
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil mengaktifkan mitra',
                icon: 'CheckIcon',
                variant: 'success',
                position: 'bottom',
              },
            })
            this.$refs.myBottomSheet.open()
          })
        }
      })
    },
    copyNohp(mitra) {
      const index = this.mitraAround.indexOf(mitra)

      navigator.clipboard.writeText(this.mitraAround[index].no_hp).then(() => {
        this.$refs.myBottomSheet.close()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `No HP mitra ${this.mitraAround[index].no_hp} sudah disalin`,
            icon: 'CopyIcon',
            variant: 'success',
          },
        })
      })
    },
    copyNohpAllMitra(mitra) {
      navigator.clipboard.writeText(mitra.no_hp).then(() => {
        this.$refs.myBottomSheet.close()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `No hp mitra ${mitra.no_hp} sudah disalin`,
            icon: 'CopyIcon',
            variant: 'success',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
.bottom-sheet {
  padding: 16px 16px 200px 16px
}
.hitsList {
  list-style-type: none !important;
  padding-left: 0;
}
</style>
